<template>
    <div class="record-container">
        <div class="record-content">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>
                    <span class="color-blue" @click="goBack">{{courseName}}</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>考勤</el-breadcrumb-item>
            </el-breadcrumb>
            <el-table :data="recordsList" border style="width: 100%; flex: 1; margin-top: 20px" height="1%"  size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa', textAlign: 'center'}" :cell-style="{fontSize: '12px',color: '#333', textAlign: 'center'}">
                <el-table-column prop="id" label="序号"></el-table-column>
                <el-table-column prop="s_name" label="姓名"></el-table-column>
                <el-table-column label="上课时长">
                    <template slot-scope="scope">
                        <span>暂无数据</span>
                    </template>
                </el-table-column>
                <el-table-column prop="begin_time" label="进入课程时间"></el-table-column>
            </el-table>
            <el-pagination class="pages-center" background
                           :current-page.sync="recordsPages.currentPageNum"
                           :page-size="recordsPages.eachPageNum"
                           :total="recordsPages.total"
                           layout="prev, pager, next, jumper"
                           @current-change="recordCurrentChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                recordId: Number(this.$route.query.recordId) || '',
                courseName: this.$route.query.courseName || '',
                recordsList: [],
                recordsPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            this.getRecordsList();
        },
        methods: {
            getRecordsList() {
                let params = {
                    course_record_id: this.recordId,
                    page: this.recordsPages.currentPageNum,
                    limit: this.recordsPages.eachPageNum,
                }
                this.$shttp.axiosGetBy(this.$api.showSignInList, params, (res) => {
                    if (res.code === 200) {
                        this.recordsList = res.data.data
                        this.recordsPages.total = res.data.total
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            recordCurrentChange(val) {
                this.recordsPages.currentPageNum = val;
                this.getRecordsList();
            },
            goBack() {
                this.$router.go(-1)
            },
        }
    }
</script>

<style scoped lang="scss">
    .record-container {
        height: calc(100% - 40px);
        background: #F2F2F2;
        padding: 20px 0;
        .record-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            width: 1200px;
            margin: 0 auto;
        }
    }
    ::v-deep .el-breadcrumb__inner {
        color: #000;
    }
</style>